import React, {ReactElement} from 'react';
import {useSelector} from 'react-redux';
import {playersMapSelector, roundPlayersSelector, uiSelector} from '../store/Ui';
import {cards} from '../Card';
import {Grid} from 'semantic-ui-react';
import {Stack} from 'doko-cards-common/src/Cards';
import classNames from 'classnames';

function sumStack(stack: Stack): number {
  return stack.reduce((s, [, cardId]) => s + cards[cardId].value, 0);
}

function PlayerTile({id}: {id: string}): ReactElement {
  const {tricks} = useSelector(uiSelector);
  const playerTricks = tricks.map((t, index) => ({...t, no: index + 1})).filter(({userId}) => userId === id);
  const countPoints = playerTricks.reduce((acc, {stack}) => acc + sumStack(stack), 0);
  const playersMap = useSelector(playersMapSelector);
  const player = playersMap.get(id)!;
  return <div className={'playerTile'}>
    <div className={'playerTile-name'}>{player.name}</div>
    <div className={'playerTile-points'}>{countPoints}</div>
    <div className={'playerTile-tricks'}>
      {playerTricks.map(({no, stack}, index) => {
        const isDoppelKopf = sumStack(stack) >= 40;
        return <div key={index} className={classNames('playerTile-trick', {isDoppelKopf})}>
          {stack.map(([userId, cardId]) => {
            const foreignFox = userId !== id && (cardId === 'ad1' || cardId === 'ad2');
            const karlLastTrick = no === 10 && (cardId === 'jc1' || cardId === 'jc2');
            const cardHighLight = foreignFox || karlLastTrick;
            const Svg = cards[cardId].Svg;
            return <div className={classNames('playerTile-trick-svg', {cardHighLight})} key={cardId}>
              <Svg />
              <div className={'playerTile-trick-svg-userChar'}>{playersMap.get(userId)?.name?.[0]}</div>
            </div>;
          })}
        </div>;
      })}
    </div>
  </div>;
}

export default function ResultsTable(): ReactElement {
  const {activePlayerIds} = useSelector(uiSelector);
  const roundPlayers = useSelector(roundPlayersSelector);
  return <Grid className={'playTable'}>
    {roundPlayers.filter((p) => activePlayerIds.includes(p.id))
      .map((p) => <PlayerTile key={p.id} id={p.id} />)}
  </Grid>;
}
