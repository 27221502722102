import React, {ReactElement, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {playersMapSelector, uiSelector} from '../store/Ui';
import {cards} from '../Card';
import {Icon, Message, Transition} from 'semantic-ui-react';
import classNames from 'classnames';
import {LoguxDispatch} from 'doko-cards-common/@types/loguxRedux';
import {ActionHideLastTrick, ActionShowLastTrick} from 'doko-cards-common/src/Actions';
import {currentUserId} from '../CurrentUser';

export default function LastTrick(): ReactElement | null {
  const dispatch = useDispatch<LoguxDispatch>();
  const {lastTrick, showLastTrick, showLastTrickRequesterIds} = useSelector(uiSelector);
  const show = useCallback(() => dispatch.sync<ActionShowLastTrick>({type: 'show-last-trick'}), [dispatch]);
  const hide = useCallback(() => dispatch.sync<ActionHideLastTrick>({type: 'hide-last-trick'}), [dispatch]);
  const playersMap = useSelector(playersMapSelector);
  const hasShown = showLastTrickRequesterIds.includes(currentUserId);
  const lastRequesterId = showLastTrickRequesterIds[showLastTrickRequesterIds.length - 1];

  if (!lastTrick) {
    return null;
  }

  return <div className={'lastTrick'}>
    <div className={classNames('lastTrick-toggle', {clickable: !hasShown})}
      onClick={hasShown ? undefined : show}>
      <Icon name={hasShown ? 'eye slash' : 'eye'} />
    </div>

    <Transition.Group animation={'fly right'} duration={500}>
      {showLastTrick && <div onClick={lastRequesterId === currentUserId ? hide : undefined}>
        <Message color={'black'}>
          <Message.Content>
            <div>{playersMap.get(lastRequesterId)?.name}: <i>Letzter Stich?</i></div>
            <div className={'lastTrick-stack'}>
              {lastTrick.stack.map(([playerId, cardId]) => {
                const Svg = cards[cardId].Svg;
                return <div key={playerId} className={'lastTrick-stack-svg'}>
                  <Svg />
                  <div className={'lastTrick-stack-svg-userChar'}>{playersMap.get(playerId)?.name?.[0]}</div>
                </div>;
              })}
            </div>
          </Message.Content>
          <div className={'lastTrick-progress'}>
            <div className={'lastTrick-progress-bar'}></div>
          </div>
        </Message>
      </div>}
    </Transition.Group>
  </div>;
}
