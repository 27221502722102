import {ReactComponent as ac} from './icons/cards/ac.svg';
import {ReactComponent as ad} from './icons/cards/ad.svg';
import {ReactComponent as ah} from './icons/cards/ah.svg';
import {ReactComponent as as} from './icons/cards/as.svg';
import {ReactComponent as jc} from './icons/cards/jc.svg';
import {ReactComponent as jd} from './icons/cards/jd.svg';
import {ReactComponent as jh} from './icons/cards/jh.svg';
import {ReactComponent as js} from './icons/cards/js.svg';
import {ReactComponent as kc} from './icons/cards/kc.svg';
import {ReactComponent as kd} from './icons/cards/kd.svg';
import {ReactComponent as kh} from './icons/cards/kh.svg';
import {ReactComponent as ks} from './icons/cards/ks.svg';
import {ReactComponent as qc} from './icons/cards/qc.svg';
import {ReactComponent as qd} from './icons/cards/qd.svg';
import {ReactComponent as qh} from './icons/cards/qh.svg';
import {ReactComponent as qs} from './icons/cards/qs.svg';
import {ReactComponent as tc} from './icons/cards/tc.svg';
import {ReactComponent as td} from './icons/cards/td.svg';
import {ReactComponent as th} from './icons/cards/th.svg';
import {ReactComponent as ts} from './icons/cards/ts.svg';
import {FunctionComponent} from 'react';
import {CardId} from 'doko-cards-common/src/Cards';

export interface Card {
  id: CardId;
  name: string;
  value: number;
  Svg: FunctionComponent;
}

export const cards: {[id: string]: Card} = {
  ac1: {id: 'ac1', name: 'Kreuz Ass', value: 11, Svg: ac},
  ad1: {id: 'ad1', name: 'Karo Ass', value: 11, Svg: ad},
  ah1: {id: 'ah1', name: 'Herz Ass', value: 11, Svg: ah},
  as1: {id: 'as1', name: 'Pick Ass', value: 11, Svg: as},
  jc1: {id: 'jc1', name: 'Kreuz Bube', value: 2, Svg: jc},
  jd1: {id: 'jd1', name: 'Karo Bube', value: 2, Svg: jd},
  jh1: {id: 'jh1', name: 'Herz Bube', value: 2, Svg: jh},
  js1: {id: 'js1', name: 'Pick Bube', value: 2, Svg: js},
  kc1: {id: 'kc1', name: 'Kreuz König', value: 4, Svg: kc},
  kd1: {id: 'kd1', name: 'Karo König', value: 4, Svg: kd},
  kh1: {id: 'kh1', name: 'Herz König', value: 4, Svg: kh},
  ks1: {id: 'ks1', name: 'Pick König', value: 4, Svg: ks},
  qc1: {id: 'qc1', name: 'Kreuz Dame', value: 3, Svg: qc},
  qd1: {id: 'qd1', name: 'Karo Dame', value: 3, Svg: qd},
  qh1: {id: 'qh1', name: 'Herz Dame', value: 3, Svg: qh},
  qs1: {id: 'qs1', name: 'Pick Dame', value: 3, Svg: qs},
  tc1: {id: 'tc1', name: 'Kreuz Zehn', value: 10, Svg: tc},
  td1: {id: 'td1', name: 'Karo Zehn', value: 10, Svg: td},
  th1: {id: 'th1', name: 'Herz Zehn', value: 10, Svg: th},
  ts1: {id: 'ts1', name: 'Pick Zehn', value: 10, Svg: ts},
  ac2: {id: 'ac2', name: 'Kreuz Ass', value: 11, Svg: ac},
  ad2: {id: 'ad2', name: 'Karo Ass', value: 11, Svg: ad},
  ah2: {id: 'ah2', name: 'Herz Ass', value: 11, Svg: ah},
  as2: {id: 'as2', name: 'Pick Ass', value: 11, Svg: as},
  jc2: {id: 'jc2', name: 'Kreuz Bube', value: 2, Svg: jc},
  jd2: {id: 'jd2', name: 'Karo Bube', value: 2, Svg: jd},
  jh2: {id: 'jh2', name: 'Herz Bube', value: 2, Svg: jh},
  js2: {id: 'js2', name: 'Pick Bube', value: 2, Svg: js},
  kc2: {id: 'kc2', name: 'Kreuz König', value: 4, Svg: kc},
  kd2: {id: 'kd2', name: 'Karo König', value: 4, Svg: kd},
  kh2: {id: 'kh2', name: 'Herz König', value: 4, Svg: kh},
  ks2: {id: 'ks2', name: 'Pick König', value: 4, Svg: ks},
  qc2: {id: 'qc2', name: 'Kreuz Dame', value: 3, Svg: qc},
  qd2: {id: 'qd2', name: 'Karo Dame', value: 3, Svg: qd},
  qh2: {id: 'qh2', name: 'Herz Dame', value: 3, Svg: qh},
  qs2: {id: 'qs2', name: 'Pick Dame', value: 3, Svg: qs},
  tc2: {id: 'tc2', name: 'Kreuz Zehn', value: 10, Svg: tc},
  td2: {id: 'td2', name: 'Karo Zehn', value: 10, Svg: td},
  th2: {id: 'th2', name: 'Herz Zehn', value: 10, Svg: th},
  ts2: {id: 'ts2', name: 'Pick Zehn', value: 10, Svg: ts},
};
