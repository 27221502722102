import React, {Fragment, ReactElement} from 'react';
import {useSelector} from 'react-redux';
import {roundPlayersSelector, uiSelector} from '../store/Ui';
import {Grid} from 'semantic-ui-react';
import {Player} from 'doko-cards-common/src/Players';
import classNames from 'classnames';

export default function PlayersTable({PlayerTile}: {PlayerTile: React.FunctionComponent<{player: Player}>}): ReactElement {
  const {activePlayerIds} = useSelector(uiSelector);
  const roundPlayers = useSelector(roundPlayersSelector);
  return <Grid className={classNames('playersTable', {withInactive: roundPlayers.length > 4})}>
    {roundPlayers.map((p, index) => {
      const isActive = activePlayerIds.includes(p.id);
      return <Fragment key={p.id}>
        {isActive && <PlayerTile player={p} />}
        {!isActive && <div className={'playerTile inactive'} style={{left: `${index * 25 - 12.5}%`}}>
          <div className={'playerTile-name'}>{p.name}</div>
        </div>}
      </Fragment>;
    })}
  </Grid>;
}
