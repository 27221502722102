import React, {ReactElement} from 'react';
import {useSelector} from 'react-redux';
import {stackSelector, startingPlayerIdSelector, uiSelector} from '../store/Ui';
import {cards} from '../Card';
import {Player} from 'doko-cards-common/src/Players';
import classNames from 'classnames';

export default function PlayingPlayerTile({player}: {player: Player}): ReactElement {
  const {trickUserIds} = useSelector(uiSelector);
  const countTricks = trickUserIds.reduce((acc, trickUserId) => acc + (trickUserId === player.id ? 1 : 0), 0);
  const stack = useSelector(stackSelector);
  const isStarting = useSelector(startingPlayerIdSelector) === player.id;
  const Svg = stack.has(player.id) ? cards[stack.get(player.id)!].Svg : null;
  return <div className={'playerTile playing'}>
    <div className={classNames('playerTile-name', {isStarting})}>{player.name} ({countTricks})</div>
    <div className={'playerTile-card'}>{Svg && <Svg />}</div>
  </div>;
}
