import React, {ReactElement} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {LoguxDispatch} from 'doko-cards-common/@types/loguxRedux';
import {Button, Form} from 'semantic-ui-react';
import {ActionDeal} from 'doko-cards-common/src/Actions';
import {roundPlayersSelector} from '../store/Ui';

export default function DealNewGame(): ReactElement {
  const dispatch = useDispatch<LoguxDispatch>();
  const roundPlayers = useSelector(roundPlayersSelector);
  return <div className={'flex-column-centered'}>
    <Form className={'text-align-center'}>
      {roundPlayers.map(({id, name}) => <Form.Field key={id}>
        <Button primary onClick={() => dispatch.sync<ActionDeal>({type: 'deal', dealerId: id})}>{name} gibt</Button>
      </Form.Field>)}
    </Form>
  </div>;
}
