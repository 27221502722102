import {DeepPartial, mergeStates} from 'doko-cards-common';
import {createReducer} from './Reducer';
import {useDispatch} from 'react-redux';
import {useCallback} from 'react';
import {LoguxDispatch} from 'doko-cards-common/@types/loguxRedux';
import {Card, cards} from '../Card';
import {State} from './Store';
import {CardId, SerializedTrick, Stack} from 'doko-cards-common/src/Cards';
import {
  ActionCardPlayed,
  ActionCardsDealt,
  ActionCardWithdrawn,
  ActionGameFinished,
  ActionGameStarted,
  ActionGivePoverty,
  ActionHealthReported,
  ActionLoaded,
  ActionNextGame,
  ActionPatchAttendance,
  ActionPlayCard,
  ActionPovertyGiven,
  ActionPovertyRejected,
  ActionPovertyReturned,
  ActionPovertyTaken,
  ActionReturnPoverty,
  ActionToggleLastTrick,
  ActionTrickReturned,
  ActionTrickTaken,
  GameState,
  PlayerStatus,
} from 'doko-cards-common/src/Actions';
import {createSelector} from 'reselect';
import {currentUserId} from '../CurrentUser';
import {arrayMoveImmutable as arrayMove} from 'array-move';
import {intersection} from 'lodash';
import {Player} from 'doko-cards-common/src/Players';

export interface RoundData {
  players: Player[];
  roundPlayerIds: string[];
}

export interface GameData {
  activePlayerIds: string[];
  dealerId: string;
  gameId: string;
  gameState: GameState;
  isPoor: boolean;
  lastTrick: SerializedTrick | null;
  myCards: Card[];
  playerHealth: PlayerStatus;
  povertyAnswers: PlayerStatus;
  povertyPlayer: string | null;
  selectedCards: CardId[];
  showLastTrick: boolean;
  showLastTrickRequesterIds: string[];
  stack: Stack;
  tricks: SerializedTrick[];
  trickUserIds: string[];
}

export type Ui = RoundData & GameData;

export interface UiSet {
  type: 'ui/set';
  ui: DeepPartial<Ui>;
}

export interface UiToggleCard {
  type: 'ui/toggleCard';
  cardId: CardId;
}

export interface UiSortCard {
  type: 'ui/sortCard';
  oldIndex: number;
  newIndex: number;
}

const roundData: RoundData = {
  players: [],
  roundPlayerIds: [],
};

const gameData: GameData = {
  activePlayerIds: [],
  dealerId: '',
  gameId: '',
  gameState: 'loading',
  isPoor: false,
  lastTrick: null,
  myCards: [],
  playerHealth: [],
  povertyAnswers: [],
  povertyPlayer: null,
  selectedCards: [],
  showLastTrick: false,
  showLastTrickRequesterIds: [],
  stack: [],
  tricks: [],
  trickUserIds: [],
};

const initial: Ui = {
  ...roundData,
  ...gameData,
};

const {addReducer, combinedReducer} = createReducer<Ui>(initial);

const clearSelectedCards = (state: Ui): Ui => mergeStates(state, {selectedCards: []});

function replaceMyCards(myCards: Card[], newCardIds: CardId[]): Card[] {
  const newSet = new Set(newCardIds);
  const newArray = myCards.filter(({id}) => {
    const alreadyExisting = newSet.has(id);
    if (alreadyExisting) {
      newSet.delete(id);
    }
    return alreadyExisting;
  });
  newSet.forEach((newId) => newArray.push(cards[newId]));
  return newArray;
}

function hasCardIds(action: object): action is {myCardIds: CardId[]} {
  return action.hasOwnProperty('myCardIds');
}

function autoPatch(state: Ui, action: Partial<Ui>): Ui {
  const newState = {...state};
  Object.entries(action).forEach(([k, v]) => {
    if (newState.hasOwnProperty(k)) {
      // @ts-ignore
      newState[k] = v;
    }
  });
  if (hasCardIds(action)) {
    newState.myCards = replaceMyCards(newState.myCards, action.myCardIds);
    if (newState.myCards.length === 1) {
      newState.selectedCards = [newState.myCards[0].id];
    }
  }
  return newState;
}

addReducer<UiSet>('ui/set', (state, action) => mergeStates(state, action.ui));

addReducer<UiToggleCard>('ui/toggleCard', (state, {cardId}) => {
  const prev = new Set(state.selectedCards);
  if (state.isPoor || state.myCards.length === 13) {
    if (prev.has(cardId)) {
      prev.delete(cardId);
    } else if (prev.size < 3) {
      prev.add(cardId);
    }
  } else {
    if (prev.has(cardId)) {
      prev.clear();
    } else {
      prev.clear();
      prev.add(cardId);
    }
  }
  return {
    ...state,
    selectedCards: [...prev],
  };
});

addReducer<UiSortCard>('ui/sortCard', (state, {oldIndex, newIndex}) => {
  const myCards = arrayMove(state.myCards, oldIndex, newIndex);
  localStorage.setItem('cardOrder', JSON.stringify({
    gameId: state.gameId,
    playerId: currentUserId,
    myCardIds: myCards.map(({id}) => id),
  }));
  return {...state, myCards};
});

addReducer<ActionLoaded>('loaded', (state, action) => {
  const order = localStorage.getItem('cardOrder');
  const json = order ? JSON.parse(order) : null;
  if (json && json.gameId === action.gameId && json.playerId === currentUserId) {
    action.myCardIds = intersection(json.myCardIds, action.myCardIds);
  }
  return {...autoPatch(state, action), isPoor: false, selectedCards: []};
});
addReducer<ActionPatchAttendance>('patch-attendance', autoPatch);
addReducer<ActionCardsDealt>('cards-dealt', (state, action) => {
  return {...autoPatch(state, action), isPoor: false, selectedCards: []};
});
addReducer<ActionHealthReported>('health-reported', autoPatch);
addReducer<ActionGameStarted>('game-started', autoPatch);
addReducer<ActionGivePoverty>('give-poverty', (state) => mergeStates(state, {isPoor: false, selectedCards: []}));
addReducer<ActionPovertyGiven>('poverty-given', autoPatch);
addReducer<ActionPovertyRejected>('poverty-rejected', autoPatch);
addReducer<ActionPovertyTaken>('poverty-taken', autoPatch);
addReducer<ActionReturnPoverty>('return-poverty', clearSelectedCards);
addReducer<ActionPovertyReturned>('poverty-returned', autoPatch);
addReducer<ActionPlayCard>('play-card', clearSelectedCards);
addReducer<ActionCardPlayed>('card-played', autoPatch);
addReducer<ActionCardWithdrawn>('card-withdrawn', autoPatch);
addReducer<ActionTrickTaken>('trick-taken', autoPatch);
addReducer<ActionTrickReturned>('trick-returned', autoPatch);
addReducer<ActionGameFinished>('game-finished', autoPatch);
addReducer<ActionToggleLastTrick>('toggle-last-trick', autoPatch);
addReducer<ActionNextGame>('next-game', (state, action) => {
  return {...state, ...gameData, gameState: action.gameState};
});

export const uiReducer = combinedReducer;

export const uiSelector = (state: State) => state.ui;
export const stackSelector = createSelector(uiSelector, ({stack}) => new Map(stack));
export const isPlayingSelector = createSelector(uiSelector,
  ({activePlayerIds}) => activePlayerIds.includes(currentUserId));

export const playersSelector = (state: State) => state.ui.players;
export const playersMapSelector = createSelector(playersSelector, (players) => new Map(players.map((p) => [p.id, p])));

export const roundPlayerIdsSelector = (state: State) => state.ui.roundPlayerIds;
export const roundPlayersSelector = createSelector(
  roundPlayerIdsSelector,
  playersMapSelector,
  (roundPlayerIds, players) => roundPlayerIds.map((id) => players.get(id)!),
);

export const startingPlayerIdSelector = createSelector(
  uiSelector,
  ({activePlayerIds, trickUserIds}) => {
    if (trickUserIds.length) {
      return trickUserIds[trickUserIds.length - 1]!;
    }
    return activePlayerIds[0];
  },
);

export const nextPlayerAskedForPovertyIdSelector = createSelector(
  uiSelector,
  ({activePlayerIds, trickUserIds, povertyPlayer, povertyAnswers}) => {
    if (!povertyPlayer) {
      return null;
    }
    const idx = activePlayerIds.indexOf(povertyPlayer);
    const answersMap = new Map(povertyAnswers);
    for (let i = 1; i < 4; i++) {
      const id = activePlayerIds[(idx + i) % 4];
      if (!answersMap.has(id)) {
        return id;
      }
    }
    return null;
  },
);

export function useSetUi() {
  const dispatch = useDispatch<LoguxDispatch>();
  return useCallback((ui: DeepPartial<Ui>): void => {
    dispatch({type: 'ui/set', ui} as UiSet);
  }, [dispatch]);
}
