import React, {ReactElement} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {LoguxDispatch} from 'doko-cards-common/@types/loguxRedux';
import {uiSelector} from '../store/Ui';
import {Button} from 'semantic-ui-react';
import {ActionReturnPoverty} from 'doko-cards-common/src/Actions';

function ReturnPovertyControls(): ReactElement {
  const dispatch = useDispatch<LoguxDispatch>();
  const {selectedCards} = useSelector(uiSelector);
  return <>
    <div />
    <div>Du hast die Armut angenommen! Wähle 3 Karten</div>
    <Button primary disabled={selectedCards.length !== 3} onClick={() => {
      dispatch.sync<ActionReturnPoverty>({
        type: 'return-poverty',
        cardIds: selectedCards,
      });
    }}>Zurück geben</Button>
  </>;
}

function Waiting(): ReactElement {
  return <>
    <div />
    <div>Warten auf Armut Rückgabe</div>
    <div />
  </>;
}

export default function WaitingForPovertyReturnControls(): ReactElement {
  const {myCards} = useSelector(uiSelector);
  const povertyReceived = myCards.length === 13;
  return <>
    {povertyReceived && <ReturnPovertyControls />}
    {!povertyReceived && <Waiting />}
  </>;
}
