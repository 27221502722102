import React, {ReactElement, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {stackSelector, uiSelector} from '../store/Ui';
import {Button} from 'semantic-ui-react';
import {LoguxDispatch} from 'doko-cards-common/@types/loguxRedux';
import {CardId} from 'doko-cards-common/src/Cards';
import {currentUserId} from '../CurrentUser';
import {
  ActionFinishGame,
  ActionPlayCard,
  ActionReturnTrick,
  ActionTakeTrick,
  ActionWithdrawCard,
} from 'doko-cards-common/src/Actions';

export default function PlayingControls(): ReactElement {
  const {myCards, selectedCards, trickUserIds} = useSelector(uiSelector);
  const stack = useSelector(stackSelector);
  const dispatch = useDispatch<LoguxDispatch>();
  const stackMap = useMemo<Map<string, CardId>>(() => new Map(stack), [stack]);
  const hasPlayed = stackMap.has(currentUserId);
  const canReturnTrick = stack.size === 0 && trickUserIds[trickUserIds.length - 1] === currentUserId;
  const stackComplete = stack.size === 4;
  const isEnd = myCards.length === 0;

  return <>
    {hasPlayed && <>
      {stackComplete && <Button primary onClick={() => dispatch.sync<ActionTakeTrick>({type: 'take-trick'})}>
        Stich nehmen
      </Button>}
      {!stackComplete && <div />}
      <div />
      <Button secondary onClick={() => dispatch.sync<ActionWithdrawCard>({type: 'withdraw-card'})}>Karte
        zurückziehen</Button>
    </>}

    {!hasPlayed && <>
      {canReturnTrick && <Button secondary onClick={() => dispatch.sync<ActionReturnTrick>({type: 'return-trick'})}>
        Stich zurücklegen
      </Button>}
      {!canReturnTrick && <div />}

      {!isEnd && <>
        <Button className={'flex-1'} primary disabled={selectedCards.length !== 1} onClick={() => {
          dispatch.sync<ActionPlayCard>({type: 'play-card', cardId: selectedCards[0]});
        }}>Wähle 1 Karte zum Spielen</Button>
      </>}

      {isEnd && <>
        <Button positive onClick={() => dispatch.sync<ActionFinishGame>({type: 'finish-game'})}>
          Spiel auswerten
        </Button>
        <div />
      </>}
    </>}
  </>;
}
