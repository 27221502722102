import React, {ReactElement, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {uiSelector, useSetUi} from '../store/Ui';
import {Button, Checkbox, Grid} from 'semantic-ui-react';
import {currentUserId} from '../CurrentUser';
import {LoguxDispatch} from 'doko-cards-common/@types/loguxRedux';
import {ActionGivePoverty, ActionReportHealth} from 'doko-cards-common/src/Actions';

function IsPoorCheckbox(): ReactElement {
  const {isPoor} = useSelector(uiSelector);
  const patchUi = useSetUi();
  return <Checkbox className={'inverted'} label={'Armut'} checked={isPoor} onClick={() => {
    patchUi({selectedCards: [], isPoor: !isPoor});
  }} />;
}

export default function PreGameControls(): ReactElement {
  const {isPoor, playerHealth, selectedCards} = useSelector(uiSelector);
  const health = playerHealth.find(([uId]) => uId === currentUserId);
  const isUnhealthy = Boolean(health && !health[1]);
  const isHealthy = Boolean(health && health[1]);
  const dispatch = useDispatch<LoguxDispatch>();
  const report = useCallback((healthy: boolean): void => {
    dispatch.sync<ActionReportHealth>({type: 'report-health', healthy});
  }, [dispatch]);
  return <Grid columns={3} centered>
    {isPoor && <>
      <Grid.Column textAlign={'center'} verticalAlign={'middle'}><IsPoorCheckbox /></Grid.Column>
      <Grid.Column textAlign={'center'} verticalAlign={'middle'}>Wähle 3 Karten</Grid.Column>
      <Grid.Column textAlign={'center'} verticalAlign={'middle'}>
        <Button primary disabled={selectedCards.length !== 3} onClick={() => {
          dispatch.sync<ActionGivePoverty>({
            type: 'give-poverty',
            cardIds: selectedCards,
          });
        }}>Abgeben</Button>
      </Grid.Column>
    </>}

    {!isPoor && <>
      <Grid.Column textAlign={'center'} verticalAlign={'middle'}>{isUnhealthy && <IsPoorCheckbox />}</Grid.Column>
      <Grid.Column textAlign={'center'} verticalAlign={'middle'}>
        <Button.Group>
          <Button secondary={!isUnhealthy} negative={isUnhealthy} onClick={() => report(false)}>Vorbehalt</Button>
          <Button.Or />
          <Button secondary={!isHealthy} positive={isHealthy} onClick={() => report(true)}>Gesund</Button>
        </Button.Group>
      </Grid.Column>
      <Grid.Column textAlign={'center'} verticalAlign={'middle'} />
    </>}
  </Grid>;
}
