import React, {ReactElement, useRef} from 'react';
import {Checkbox, Form, Grid, Header, Icon, Label, Modal} from 'semantic-ui-react';
import {useDispatch, useSelector} from 'react-redux';
import {playersSelector, roundPlayerIdsSelector, roundPlayersSelector} from '../store/Ui';
import {LoguxDispatch} from 'doko-cards-common/@types/loguxRedux';
import {ActionPatchAttendance} from 'doko-cards-common/src/Actions';
import {difference} from 'lodash';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import {Player} from 'doko-cards-common/src/Players';
import {arrayMoveImmutable as arrayMove} from 'array-move';

const SortablePlayer = SortableElement(({player}: {player: Player}) => {
  return <div className={'sortablePlayer'}>
    <Label as='div' size={'large'}>
      <Icon name='user' />
      {player.name}
    </Label>
  </div>;
});

const SortablePlayers = SortableContainer(({players}: {players: Player[]}) => <div className={'sortablePlayers'}>
  {players.map((player, index) => <SortablePlayer key={player.id} index={index} player={player} />)}
</div>);

export default function SittingOrderPopup({open, close}: {open: boolean; close: () => void}): ReactElement {
  const players = useSelector(playersSelector);
  const roundPlayerIds = useSelector(roundPlayerIdsSelector);
  const roundPlayers = useSelector(roundPlayersSelector);
  const dispatch = useDispatch<LoguxDispatch>();
  const sortHelper = useRef<HTMLDivElement>(null);
  return <Modal closeIcon open={open} onClose={close}>
    <Modal.Content>
      <Grid columns={2}>
        <Grid.Column>
          <Header>Teilnehmer</Header>
          <Form>
            {players.map((p) => {
              const checked = roundPlayerIds.includes(p.id);
              return <Form.Field key={p.id}>
                <Checkbox label={p.name} checked={checked} onClick={() => {
                  const newIds = checked ? difference(roundPlayerIds, [p.id]) : [...roundPlayerIds, p.id];
                  dispatch.sync<ActionPatchAttendance>({type: 'patch-attendance', roundPlayerIds: newIds});
                }} />
              </Form.Field>;
            })}
          </Form>
        </Grid.Column>

        <Grid.Column>
          <Header>Sitzfolge</Header>
          <div className={'sittingOrder'}>
            <SortablePlayers players={roundPlayers}
              onSortEnd={({oldIndex, newIndex}) => {
                const newIds = arrayMove(roundPlayerIds, oldIndex, newIndex);
                dispatch.sync<ActionPatchAttendance>({type: 'patch-attendance', roundPlayerIds: newIds});
              }}
              helperContainer={() => sortHelper.current!} />
            <div ref={sortHelper} />
          </div>
        </Grid.Column>
      </Grid>
    </Modal.Content>
  </Modal>;
}
