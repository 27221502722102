import React, {ReactElement} from 'react';
import {useSelector} from 'react-redux';
import {Divider, Label} from 'semantic-ui-react';
import {playersMapSelector, startingPlayerIdSelector, uiSelector} from '../store/Ui';
import PlayersTable from './PlayersTable';
import {Player} from 'doko-cards-common/src/Players';
import classNames from 'classnames';

function PlayerTile({player}: {player: Player}): ReactElement {
  const {povertyPlayer, povertyAnswers} = useSelector(uiSelector);
  const playsPoverty = povertyPlayer === player.id;
  const answer = povertyAnswers.find(([uId]) => uId === player.id);
  const isStarting = useSelector(startingPlayerIdSelector) === player.id;
  return <div className={'playerTile'}>
    <div className={classNames('playerTile-name', {isStarting})}>{player.name}</div>
    <div className={'playerTile-health'}>
      {playsPoverty && <Label color='teal'>Armut</Label>}
      {!playsPoverty && answer && <>
        {answer[1] && <Label color='green'>Angenommen</Label>}
        {!answer[1] && <Label color='red'>Weitergegeben</Label>}
      </>}
    </div>
  </div>;
}

export default function PovertyPartnerTable(): ReactElement {
  const {povertyPlayer} = useSelector(uiSelector);
  const playersMap = useSelector(playersMapSelector);
  return <div className={'flex-column-centered'}>
    <Label size={'large'} color={'black'}>{playersMap.get(povertyPlayer!)?.name} spielt Armut</Label>
    <Divider />
    <PlayersTable PlayerTile={PlayerTile} />
  </div>;
}
