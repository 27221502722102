import React, {ReactElement} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {nextPlayerAskedForPovertyIdSelector} from '../store/Ui';
import {currentUserId} from '../CurrentUser';
import {LoguxDispatch} from 'doko-cards-common/@types/loguxRedux';
import {Button} from 'semantic-ui-react';
import {ActionRejectPoverty, ActionTakePoverty} from 'doko-cards-common/src/Actions';

export default function SelectPovertyPartnerControls(): ReactElement {
  const yourTurn = useSelector(nextPlayerAskedForPovertyIdSelector) === currentUserId;
  const dispatch = useDispatch<LoguxDispatch>();
  return <>
    <div />
    {yourTurn && <>
      <Button positive onClick={() => dispatch.sync<ActionTakePoverty>({type: 'take-poverty'})}>Armut annehmen</Button>
      <Button negative onClick={() => dispatch.sync<ActionRejectPoverty>({type: 'reject-poverty'})}>Weitergeben</Button>
    </>}
    <div />
  </>;
}
