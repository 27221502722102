import React, {ReactElement, useRef} from 'react';
import classNames from 'classnames';
import {SortableCards} from './SortableCards';
import {uiSelector, UiSortCard} from '../store/Ui';
import {useDispatch, useSelector} from 'react-redux';
import {LoguxDispatch} from 'doko-cards-common/@types/loguxRedux';

export default function MyCards(): ReactElement {
  const dispatch = useDispatch<LoguxDispatch>();
  const {myCards} = useSelector(uiSelector);
  const helperContainerRef = useRef(null);
  const povertyReceived = myCards.length === 13;

  return <div className={classNames('myCards', {povertyReceived})}>
    <SortableCards cards={myCards}
      axis={'x'}
      distance={5}
      helperContainer={() => helperContainerRef.current!}
      onSortEnd={({oldIndex, newIndex}) => dispatch<UiSortCard>({
        type: 'ui/sortCard',
        oldIndex,
        newIndex,
      })} />

    <div ref={helperContainerRef} />
  </div>;
}
