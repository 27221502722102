import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'fomantic-ui-css/semantic.min.css';
import App from './App';
import {Provider} from 'react-redux';
// @ts-ignore
import createLoguxCreator from '@logux/redux/create-logux-creator';
// @ts-ignore
import log from '@logux/client/log';
import {storeReducer} from './store/Store';
import {currentUserId} from './CurrentUser';

const isDev = process.env.NODE_ENV === 'development';

const wsProto = window.location.protocol === 'https:' ? 'wss' : 'ws';
const createStore = createLoguxCreator({
  allowDangerousProtocol: true,
  subprotocol: '1.0.0',
  server: `${wsProto}://${window.location.hostname}/ws`,
  userId: currentUserId,
});

// @ts-ignore
const enhancer = isDev && window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__({name: 'Doko'});
const store = createStore(storeReducer, enhancer);
if (isDev) {
  log(store.client);
}
store.client.start();

ReactDOM.render(<Provider store={store}><App /></Provider>, document.getElementById('root'));
