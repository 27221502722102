import React, {ReactElement} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {LoguxDispatch} from 'doko-cards-common/@types/loguxRedux';
import {Button, Divider, Label} from 'semantic-ui-react';
import {ActionStartGame} from 'doko-cards-common/src/Actions';
import {startingPlayerIdSelector, uiSelector} from '../store/Ui';
import {Player} from 'doko-cards-common/src/Players';
import classNames from 'classnames';
import PlayersTable from './PlayersTable';

function PlayerTile({player}: {player: Player}): ReactElement {
  const {playerHealth} = useSelector(uiSelector);
  const isHealthy = playerHealth.find(([uId]) => uId === player.id);
  const isStarting = useSelector(startingPlayerIdSelector) === player.id;
  return <div className={'playerTile'}>
    <div className={classNames('playerTile-name', {isStarting})}>{player.name}</div>
    <div className={'playerTile-health'}>
      {isHealthy && isHealthy[1] && <Label color='green'>Gesund</Label>}
      {isHealthy && !isHealthy[1] && <Label color='red'>Vorbehalt</Label>}
    </div>
  </div>;
}

export default function PreGameTable(): ReactElement {
  const {isPoor, playerHealth} = useSelector(uiSelector);
  const dispatch = useDispatch<LoguxDispatch>();
  const allReported = playerHealth.length === 4;

  return <div className={'flex-column-centered'}>
    <Label size={'large'} color={'black'}>Simmer jesund?</Label>
    <Divider />
    <PlayersTable PlayerTile={PlayerTile} />
    <Divider />
    {allReported && !isPoor && <>
      <Button primary onClick={() => dispatch.sync<ActionStartGame>({type: 'start-game'})}>Spiel starten</Button>
    </>}
  </div>;
}
