import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import {Card} from '../Card';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {LoguxDispatch} from 'doko-cards-common/@types/loguxRedux';
import {uiSelector, UiToggleCard} from '../store/Ui';
import classNames from 'classnames';

export const SortableCard = SortableElement(({card}: {card: Card}) => {
  const dispatch = useDispatch<LoguxDispatch>();
  const {selectedCards} = useSelector(uiSelector);
  return <div className={classNames('sortableCard', {isSelected: selectedCards.includes(card.id)})}>
    <card.Svg />
    <div className={'sortableCard-drag'}
      onClick={() => dispatch<UiToggleCard>({type: 'ui/toggleCard', cardId: card.id})} />
  </div>;
});

export const SortableCards = SortableContainer(({cards}: {cards: Card[]}) => <div className={'sortableCards'}>
  {cards.map((card, index) => <SortableCard key={card.id} index={index} card={card} />)}
</div>);
