import React, {ReactElement} from 'react';
import './App.css';
import {Loader} from 'semantic-ui-react';
import {useSelector} from 'react-redux';
import {isPlayingSelector, playersMapSelector, uiSelector} from './store/Ui';
import {currentUserId} from './CurrentUser';
import MyCards from './components/MyCards';
import DealNewGame from './components/DealNewGame';
import WaitingForPovertyReturnControls from './components/WaitingForPovertyReturnControls';
import PlayingControls from './components/PlayingControls';
import PreGameControls from './components/PreGameControls';
import PreGameTable from './components/PreGameTable';
import PlayingPlayerTile from './components/PlayingPlayerTile';
import ResultsTable from './components/ResultsTable';
import Menu from './Menu';
import useSubscription from '@logux/redux/use-subscription';
import {ChannelLoad} from 'doko-cards-common/src/Actions';
import LastTrick from './components/LastTrick';
import PovertyPartnerTable from './components/PovertyPartnerTable';
import SelectPovertyPartnerControls from './components/SelectPovertyPartnerControls';
import PlayersTable from './components/PlayersTable';

export default function App(): ReactElement {
  useSubscription<ChannelLoad>([{channel: 'load'}]);
  const {gameState} = useSelector(uiSelector);
  const isPlaying = useSelector(isPlayingSelector);
  const playersMap = useSelector(playersMapSelector);
  if (gameState === 'loading') {
    return <Loader active />;
  }
  return (
    <div className="App">
      <div className={'playerName'}>{playersMap.get(currentUserId)?.name}</div>
      <Menu />
      {gameState === 'playing' && <LastTrick />}
      <div className={'table'}>
        {gameState === 'waitingForDeal' && <DealNewGame />}
        {gameState === 'preGame' && <PreGameTable />}
        {gameState === 'selectPovertyPartner' && <PovertyPartnerTable />}
        {gameState === 'waitingForPovertyReturn' && <PovertyPartnerTable />}
        {gameState === 'playing' && <PlayersTable PlayerTile={PlayingPlayerTile} />}
        {gameState === 'finished' && <ResultsTable />}
      </div>
      {isPlaying && <div className={'controls'}>
        {gameState === 'preGame' && <PreGameControls />}
        {gameState === 'selectPovertyPartner' && <SelectPovertyPartnerControls />}
        {gameState === 'waitingForPovertyReturn' && <WaitingForPovertyReturnControls />}
        {gameState === 'playing' && <PlayingControls />}
      </div>}
      <MyCards />
    </div>
  );
}
