import React, {ReactElement, useState} from 'react';
import {Dropdown} from 'semantic-ui-react';
import {ActionResetGame} from 'doko-cards-common/src/Actions';
import {useDispatch, useSelector} from 'react-redux';
import {LoguxDispatch} from 'doko-cards-common/@types/loguxRedux';
import SittingOrderPopup from './components/SittingOrderPopup';
import {uiSelector} from './store/Ui';

export default function Menu(): ReactElement {
  const {gameState} = useSelector(uiSelector);
  const [showSittingOrder, setShowSittingOrder] = useState(false);
  const dispatch = useDispatch<LoguxDispatch>();
  return <div className={'menu'}>
    <Dropdown icon='bars' direction='left'>
      <Dropdown.Menu>
        <Dropdown.Item icon={'refresh'} text='Neues Spiel'
          onClick={() => dispatch.sync<ActionResetGame>({type: 'reset-game'})} />
        {gameState === 'waitingForDeal' && <Dropdown.Item icon={'wheelchair'} text='Sitzfolge / Spieler'
          onClick={() => setShowSittingOrder(true)} />}
      </Dropdown.Menu>
    </Dropdown>
    <SittingOrderPopup open={showSittingOrder} close={() => setShowSittingOrder(false)} />
  </div>;
}
